import { stringify } from 'query-string';
import {
    ReportFormValues,
    ReportServerResponse,
} from './Reports.types';

type Sources =
    | {
          id: string;
          label: string;
      }[]
    | undefined;

export const buildReportQuery = (
    filters: ReportFormValues,
    sources: Sources,
) => {
    const authors = [filters.authors?.id];

    const baseQuery = {
        pub_date__date__gte: filters.pub_date__date__gte,
        pub_date__date__lte: filters.pub_date__date__lte,
        file_format: filters.file_format.id,
        ...(filters.authors?.id
            ? { authors: `[${filters.authors.id}]` }
            : {}),
        ...(filters.article_types?.id
            ? { article_type: filters.article_types.id }
            : {}),
        quote_partner__in: [] as string[],
    };

    if (sources?.length) {
        baseQuery.quote_partner__in = sources.map(
            (item) => item.id,
        );
    }

    return stringify(baseQuery);
};

export const isReportCompleted = (
    serverResponse: ReportServerResponse,
) => Boolean(serverResponse.file);
