import React, { useMemo } from 'react';

import { isEqual } from 'lodash-es';
import { Provider } from 'react-redux';
import {
    apostrophEditorContext,
    store,
} from '@apostroph/store';
import { EditorInitializer } from '../ini';

import {
    PLUGIN_ADS_SOURCE,
    PLUGIN_ADS_SOURCE_CONTROL,
    PLUGIN_BLOCK,
    PLUGIN_BLOCK_CONTROL,
    PLUGIN_BLOCK_NAME,
    PLUGIN_BLOCKQUOTE,
    PLUGIN_BLOCKQUOTE_CONTROL,
    PLUGIN_GALLERY,
    PLUGIN_GALLERY_CONTROL,
    PLUGIN_HORIZONTAL_LINE,
    PLUGIN_HORIZONTAL_LINE_CONTROL,
    PLUGIN_HTML_TEXT,
    PLUGIN_HTML_TEXT_CONTROL,
    PLUGIN_IMG,
    PLUGIN_IMG_CONTROL,
    PLUGIN_IN_PROGRESS,
    PLUGIN_IN_PROGRESS_CONTROL,
    PLUGIN_INFOGRAPH,
    PLUGIN_INFOGRAPH_CONTROL,
    PLUGIN_LEED,
    PLUGIN_LEX,
    PLUGIN_LEX_CONTROL,
    PLUGIN_MAIN,
    PLUGIN_MAIN_CONTROL,
    PLUGIN_SECTION,
    PLUGIN_SECTION_CONTROL,
    PLUGIN_VIDEO,
    PLUGIN_VIDEO_CONTROL,
    PLUGIN_UPLOAD_FILES,
    PLUGIN_UPLOAD_FILES_CONTROL,
} from '../../plugins';
import { AphostrophExternalDataContextProvider } from '../context/AphostrophExternalData';

export const GROUP_MAIN_NAME = 'GROUP_MAIN';
export const GROUP_MAIN_LABEL = 'Основные элементы';

export const GROUP_MAIN = {
    name: GROUP_MAIN_NAME,
    label: GROUP_MAIN_LABEL,
};

export const GROUP_TEXT_NAME = 'GROUP_TEXT';
export const GROUP_TEXT_LABEL = 'Текстовые';

export const GROUP_TEXT = {
    name: GROUP_TEXT_NAME,
    label: GROUP_TEXT_LABEL,
};

export const GROUP_MEDIA_NAME = 'GROUP_MEDIA';
export const GROUP_MEDIA_LABEL = 'Медиа';

export const GROUP_MEDIA = {
    name: GROUP_MEDIA_NAME,
    label: GROUP_MEDIA_LABEL,
};

export const GROUP_OTHER_NAME = 'GROUP_OTHER';
export const GROUP_OTHER_LABEL = 'Вспомогательные';

export const GROUP_OTHER = {
    name: GROUP_OTHER_NAME,
    label: GROUP_OTHER_LABEL,
};

const ArticleEditor = React.memo((prop) => {
    let props = prop as any;

    const memoStore = useMemo(() => {
        return props.isReadonly
            ? store('apostroph/preview')
            : store(undefined);
    }, [prop.isReadonly]);
    console.log('render ArticleEditor');
    return (
        <AphostrophExternalDataContextProvider
            isReadonly={props.isReadonly}
        >
            <Provider
                store={memoStore}
                context={apostrophEditorContext}
            >
                <EditorInitializer
                    initialState={{
                        data:
                            props?.initialState?.data ?? {},
                        items:
                            props?.initialState?.items ||
                            [],
                    }}
                    onChange={props?.onChange}
                    dependencyConfig={{
                        controls: {
                            [PLUGIN_BLOCK_NAME]: [
                                {
                                    ...PLUGIN_BLOCK_CONTROL,
                                    groupName:
                                        GROUP_MAIN_NAME,
                                },
                                {
                                    ...PLUGIN_LEX_CONTROL,
                                    label: 'Текстовый редактор',
                                    groupName:
                                        GROUP_TEXT_NAME,
                                },
                                {
                                    ...PLUGIN_MAIN_CONTROL,
                                    label: 'Блок Справочное',
                                    groupName:
                                        GROUP_MAIN_NAME,
                                },
                                {
                                    ...PLUGIN_BLOCKQUOTE_CONTROL,
                                    label: 'Цитата',
                                    groupName:
                                        GROUP_TEXT_NAME,
                                },
                                {
                                    ...PLUGIN_GALLERY_CONTROL,
                                    label: 'Галерея',
                                    groupName:
                                        GROUP_MEDIA_NAME,
                                },
                                {
                                    ...PLUGIN_IMG_CONTROL,
                                    label: 'Картинка',
                                    groupName:
                                        GROUP_MEDIA_NAME,
                                },
                                {
                                    ...PLUGIN_VIDEO_CONTROL,
                                    label: 'Видео',
                                    groupName:
                                        GROUP_MEDIA_NAME,
                                },
                                {
                                    ...PLUGIN_HORIZONTAL_LINE_CONTROL,
                                    label: 'Линия',
                                    groupName:
                                        GROUP_OTHER_NAME,
                                },
                                {
                                    ...PLUGIN_IN_PROGRESS_CONTROL,
                                    label: 'В процессе',
                                    groupName:
                                        GROUP_OTHER_NAME,
                                },
                                {
                                    ...PLUGIN_HTML_TEXT_CONTROL,
                                    label: 'HTML',
                                    groupName:
                                        GROUP_OTHER_NAME,
                                },
                                {
                                    ...PLUGIN_ADS_SOURCE_CONTROL,
                                    label: 'ADS Источник',
                                    groupName:
                                        GROUP_MAIN_NAME,
                                },
                                {
                                    ...PLUGIN_SECTION_CONTROL,
                                    label: 'test',
                                    groupName:
                                        GROUP_MAIN_NAME,
                                },
                                {
                                    ...PLUGIN_INFOGRAPH_CONTROL,
                                    label: 'Инфографика',
                                    groupName:
                                        GROUP_MEDIA_NAME,
                                },
                                {
                                    ...PLUGIN_UPLOAD_FILES_CONTROL,
                                    label: 'Загрузить фаил',
                                    groupName:
                                        GROUP_MEDIA_NAME,
                                },
                            ],
                        },
                        pluginElements: {
                            ...PLUGIN_BLOCK,
                            ...PLUGIN_LEED,
                            ...PLUGIN_LEX,
                            ...PLUGIN_IMG,
                            ...PLUGIN_BLOCKQUOTE,
                            ...PLUGIN_GALLERY,
                            ...PLUGIN_HORIZONTAL_LINE,
                            ...PLUGIN_HTML_TEXT,
                            ...PLUGIN_IN_PROGRESS,
                            ...PLUGIN_VIDEO,
                            ...PLUGIN_ADS_SOURCE,
                            ...PLUGIN_SECTION,
                            ...PLUGIN_MAIN,
                            ...PLUGIN_INFOGRAPH,
                            ...PLUGIN_UPLOAD_FILES,
                        },
                    }}
                />
            </Provider>
        </AphostrophExternalDataContextProvider>
    );
}, areEqual);

function areEqual(
    prevProps: {
        initialState: any;
        onChange: (e: unknown) => void;
        isReadonly: boolean;
    },
    nextProps: {
        initialState: any;
        onChange: (e: unknown) => void;
        isReadonly: boolean;
    },
) {
    return isEqual(
        prevProps.initialState,
        nextProps.initialState,
    );
}

export { ArticleEditor };
