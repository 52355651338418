import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useField, useFormikContext } from 'formik';
import { ArticleEditor } from '@mega/editor';
import { updateFileArray } from '@mega/utils/src/updateFileArrays';
import { Article, definitions } from '@mega/api';
import { vkEmbed } from '@mega/utils';

const ArticleEditorField = ({
    name,
    isReadonly = false,
}: {
    name: string;
    isReadonly?: boolean;
}) => {
    const { values, setFieldValue } = useFormikContext();
    const [field, , setters] = useField({
        name,
        type: 'text',
    });

    const getData = (type: string, data: any) => {
        return Object.values(data)?.filter(
            (item: any) => item?.type === type,
        );
    };

    const [videoArray, setVideoArray] = useState<number[]>(
        [],
    );
    const [imgArray, setImgArray] = useState<number[]>([]);
    const [infoGraphArray, setInfoGraphArray] = useState<
        number[]
    >([]);
    const [galleryArray, setGalleryArray] = useState<
        number[]
    >([]);

    useEffect(() => {
        const videoData = getData(
            'VIDEO',
            field.value?.data,
        );
        const imgData = getData('IMG', field.value?.data);
        const galleryData = getData(
            'GALLERY',
            field.value?.data,
        );

        const infoGraphData = getData(
            'INFOGRAPH',
            field.value?.data,
        );

        const videoArray = videoData?.map(
            (item: any) => item.meta,
        );

        const imgArray = imgData?.map(
            (item: any) => item.meta,
        );

        const infoGraphArray = infoGraphData?.map(
            (item: any) => item.meta,
        );

        const galleryArray = galleryData
            ?.map((item: any) => [...item.meta?.images])
            .flat();

        setVideoArray(videoArray);
        setImgArray(imgArray);
        setGalleryArray(galleryArray);
        setInfoGraphArray(infoGraphArray);
    }, [field.value]);

    useEffect(() => {
        updateFileArray({
            files: videoArray as definitions['AdminImage'][],
            field: 'videos',
            values: values as Article,
            setFieldValue,
        });
    }, [videoArray]);

    useEffect(() => {
        let currentArray = [...imgArray, ...infoGraphArray];
        if (galleryArray?.length) {
            currentArray = [
                ...currentArray,
                ...galleryArray,
            ];
        }

        updateFileArray({
            files: currentArray as definitions['AdminImage'][],
            field: 'images',
            values: values as Article,
            setFieldValue,
        });
    }, [imgArray, galleryArray, infoGraphArray]);

    useEffect(() => {
        const s = document.createElement('script');
        s.src = 'https://vk.com/js/api/openapi.js?169';

        document.body.appendChild(s);
    }, []);

    const handleScroll = () => {
        vkEmbed();
    };

    useEffect(() => {
        document.addEventListener('scroll', handleScroll, {
            once: true,
        });
    }, []);

    const value = useMemo(() => {
        return field.value;
    }, []);

    const onChange = useCallback((e: unknown) => {
        console.log(e);
        if (typeof e === 'object') {
            const value = e;
            const thisIsPropertyIntoFormikContext =
                typeof values === 'object' &&
                values !== null &&
                name in values;

            if (thisIsPropertyIntoFormikContext) {
                setters.setValue(value);
                setters.setTouched(true);
            } else {
                setFieldValue(field.name, value);
                setters.setTouched(true);
            }
        }
    }, []);

    return (
        <ArticleEditor
            isReadonly={isReadonly}
            initialState={value}
            onChange={onChange}
        />
    );
};

export { ArticleEditorField };
