import React, { FC, memo, useState } from 'react';
import {
    useArticleData,
    useInfounitTaskDispatch,
    useSiteStore,
} from '@mega/store';

import {
    Accordion,
    Button,
    Icon,
    Paper,
    Stack,
} from '@mega/ui';
import {
    ArticleInfoUnitRecipeVariants,
    styledMediaWrapper,
} from './ArticleInfoUnit.css';
import {
    ListItemInfo,
    ListItemInfoDataRender,
    SliderModal,
} from '@mega/core';
import { ArrowDown } from '@mega/icons';
import { MediaFunctionalCard } from '@mega/core/src/MediaFunctionalCard';
import { updateFileArray } from '@mega/utils/src/updateFileArrays';
import { useFormikContext } from 'formik';
import { Article } from '@mega/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

export interface ArticleInfoUnitProps {}

const urgencyLabelMap = {
    urgent: 'Молния',
    '2h': '2ч',
    '6h': '6ч',
    '12h': '12ч',
    '24h': '24ч',
    '3h': '3ч',
    not_urgent: 'Не срочно',
};

const ArticleInfoUnit: FC<
    ArticleInfoUnitProps & ArticleInfoUnitRecipeVariants
> = () => {
    const [activeFileIndex, setActiveFileIndex] = useState<
        number | null
    >(null);
    const { values, setFieldValue } =
        useFormikContext<Article>();
    const { unlinkArticle } = useInfounitTaskDispatch();
    let navigate = useNavigate();
    let location = useLocation();
    const currentSite = useSiteStore();
    const { enqueueSnackbar } = useSnackbar();

    const store = useArticleData();
    const embargo = store?.info_unit?.embargo || null;
    const urgency = store?.info_unit?.urgency || null;
    const themes = store?.info_unit?.themes || [];
    const source = store?.info_unit?.source || null;
    const regions = store?.info_unit?.regions || [];
    const videos = store?.info_unit?.videos || [];
    const images = store?.info_unit?.images || [];
    const audios = store?.info_unit?.audios || [];
    const files = store?.info_unit?.files || [];

    if (!Boolean(store?.info_unit)) {
        return null;
    }

    const mediaList = [
        ...images.map((item) => ({
            ...item,
            type: 'image',
        })),
        ...videos.map((item) => ({
            ...item,
            type: 'video',
        })),
        ...audios.map((item) => ({
            ...item,
            type: 'audio',
        })),
        ...files.map((item) => ({ ...item, type: 'file' })),
    ];

    const handleCoverUpdate = async (file: any) => {
        const isVideo = file.type === 'video';
        const field = isVideo ? 'main_video' : 'cover';
        const arrayField = isVideo ? 'videos' : 'images';
        await updateFileArray({
            files: [file],
            field: arrayField,
            values,
            setFieldValue,
        });
        await setFieldValue(field, file);
    };

    const handleUnlinkUnit = async () => {
        if (store?.id) {
            const res = await unlinkArticle(store.id);

            if (res?.isOk) {
                enqueueSnackbar(
                    'Черновик успешно отвязан',
                    {
                        variant: 'success',
                    },
                );
                navigate(
                    `/${currentSite?.id}/infounittask`,
                    {
                        replace: true,
                        state: location,
                    },
                );
            } else {
                enqueueSnackbar('Что-то пошло не так', {
                    variant: 'error',
                });
            }
        }
    };

    return (
        <Paper
            contentHeight
            color={'white'}
            variant="outline"
            classes={{
                padding: {
                    size: '24x16',
                },
            }}
        >
            <Stack gap="8">
                <Accordion
                    divider={false}
                    isOpened
                    endIcon={
                        <Icon size="18">
                            <ArrowDown
                                style={{
                                    transform:
                                        'rotate(90deg)',
                                }}
                            />
                        </Icon>
                    }
                    label={'Инфоповод'}
                    value={'info'}
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'white',
                        },
                        padding: {
                            size: '8x4',
                        },
                    }}
                >
                    <>
                        {embargo ? (
                            <ListItemInfo
                                embargo
                                label={'Важность:'}
                                data={embargo}
                            />
                        ) : urgency ? (
                            <ListItemInfo
                                label={'Важность'}
                                data={
                                    urgencyLabelMap[urgency]
                                }
                                urgency
                                urg={urgency}
                            />
                        ) : null}
                        <ListItemInfoDataRender
                            label={'Тема'}
                            themes={themes}
                        />

                        {source && (
                            <ListItemInfo
                                label={'Источник'}
                                data={source || ''}
                            />
                        )}

                        {/* TODO Не было времени переделывать ListItemInfoDataRender, вывел так, в следующий релиз поправить*/}
                        {/* {regions?.map((region, idx) => {
                            return (
                                <ListItemInfo
                                    label={
                                        !idx
                                            ? 'Муниципалитеты'
                                            : ''
                                    }
                                    data={
                                        region?.label || ''
                                    }
                                />
                            );
                        })} */}
                    </>
                </Accordion>
                <Accordion
                    divider={false}
                    isOpened
                    endIcon={
                        <Icon size="18">
                            <ArrowDown
                                style={{
                                    transform:
                                        'rotate(90deg)',
                                }}
                            />
                        </Icon>
                    }
                    label={'Медиа файлы'}
                    value={'media'}
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'white',
                        },
                        padding: {
                            size: '8x4',
                        },
                    }}
                >
                    <div className={styledMediaWrapper}>
                        {mediaList.map((item, idx) => {
                            return (
                                <MediaFunctionalCard
                                    key={item.id}
                                    onClick={() =>
                                        setActiveFileIndex(
                                            idx,
                                        )
                                    }
                                    file={item}
                                    onCoverUpdate={
                                        handleCoverUpdate
                                    }
                                    noTitle
                                />
                            );
                        })}
                    </div>
                    <SliderModal
                        files={mediaList}
                        isOpen={activeFileIndex !== null}
                        initialSlide={activeFileIndex || 0}
                        onCoverUpdate={handleCoverUpdate}
                        onClose={() =>
                            setActiveFileIndex(null)
                        }
                    />
                </Accordion>
                {store?.info_unit?.id &&
                    !store.pub_date && (
                        <Button
                            label={'Отвязать черновик'}
                            onClick={handleUnlinkUnit}
                        />
                    )}
            </Stack>
        </Paper>
    );
};

const ArticleInfoUnitMemo = memo(ArticleInfoUnit);

export { ArticleInfoUnitMemo as ArticleInfoUnit };
