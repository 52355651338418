import React, { useEffect, useState } from 'react';
import { styles as s } from './Tabs.css';
import { Typography } from '@mega/ui';
import AddIcon from '@mui/icons-material/Add';
import { MainGrid } from '@mega/api';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { Tooltip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import cn from 'classnames';
import { ContextMenu } from '../shared/ContextMenu';
import { Tab } from './Tab';

export interface NavBarProps {
    data: MainGrid[];
    disabled: boolean;
    onChange: (item: MainGrid) => void;
    handleCopy: (item?: MainGrid) => void;
    handleUpdate: (id: number, payload: MainGrid) => void;
}

export const Tabs: React.FC<NavBarProps> = ({
    data,
    onChange,
    handleUpdate,
    handleCopy,
    disabled,
}) => {
    const activeData = data.find((item) => item.is_active);
    const gridItemsOverFlow = data.length >= 10;

    const [activeId, setActiveId] = useState<
        number | undefined
    >(activeData?.id || undefined);

    const [tabTitle, setTabTitle] = useState(
        ('новое имя' as string) || '',
    );

    useEffect(() => {
        if (!activeId && activeData?.id) {
            setActiveId(activeData?.id);
        }
    }, [activeData]);

    useEffect(() => {
        const hasNoActiveId = !data.some(
            (item) => item?.id === activeId,
        );

        if (hasNoActiveId && activeData) {
            setActiveId(activeData?.id);
            onChange(activeData);
            return;
        }

        if (activeId) {
            const newId = data.filter(
                (item) => !item.is_active,
            )?.[0].id;

            setActiveId(newId);
        }
    }, [data.length]);

    const handleChange = (data?: MainGrid) => {
        if (data) {
            if (data.title) setTabTitle(data.title);
            setActiveId(data?.id);
            onChange(data);
        }
    };

    const disabledFuncWrapper = (
        e: React.MouseEvent<HTMLDivElement>,
        cb: () => void,
    ) => {
        if (disabled) {
            e.stopPropagation();
            return null;
        }

        cb();
    };

    const handleCopyWithCheck = (item?: MainGrid) => {
        if (gridItemsOverFlow) return;
        handleCopy(item);
    };

    const handleChangeTab = (
        e: React.MouseEvent<HTMLDivElement>,
        item: MainGrid,
    ) => disabledFuncWrapper(e, () => handleChange(item));

    const activeTabConfig = [
        {
            label: 'Скопировать',
            icon: <CopyAllIcon fontSize="small" />,
            cb: handleCopyWithCheck,
        },
    ];

    return (
        <div className={s.wrapper}>
            <Tooltip
                title={
                    disabled
                        ? 'Пожалуйста завершите редактирование блока'
                        : ''
                }
            >
                <div className={s.tabList}>
                    <div
                        onClick={(e) => {
                            disabledFuncWrapper(e, () =>
                                handleChange(activeData),
                            );
                        }}
                        className={cn(
                            s.navItem({
                                isCurActive:
                                    activeData?.id ===
                                    activeId,
                                isActive: true,
                            }),
                            s.disabled({
                                isDisabled: disabled,
                            }),
                        )}
                    >
                        <Typography size={'14'}>
                            Активно
                        </Typography>
                        <div
                            className={s.copyAll({
                                isActive:
                                    activeData?.id ===
                                    activeId,
                            })}
                        >
                            {activeData && (
                                <ContextMenu<MainGrid>
                                    item={activeData}
                                    config={activeTabConfig}
                                    icon={<MoreHorizIcon />}
                                />
                            )}
                        </div>
                    </div>
                    {data.map((item, idx) => {
                        if (item.id === activeData?.id) {
                            return null;
                        }
                        const isActive =
                            item.id === activeId;
                        return (
                            <Tab
                                key={item.id}
                                item={item}
                                isActive={isActive}
                                disabled={disabled}
                                handleChangeTab={
                                    handleChangeTab
                                }
                                tabTitle={tabTitle}
                                setTabTitle={setTabTitle}
                                handleUpdate={handleUpdate}
                                handleCopy={
                                    handleCopyWithCheck
                                }
                            />
                        );
                    })}
                    <Tooltip
                        title={`${
                            gridItemsOverFlow
                                ? 'Достигнуто максимальное кол-во сеток'
                                : 'Создать сетку'
                        }`}
                    >
                        <div
                            className={cn(
                                s.addIcon,
                                s.disabled({
                                    isDisabled:
                                        disabled ||
                                        gridItemsOverFlow,
                                }),
                            )}
                            onClick={(e) =>
                                disabledFuncWrapper(
                                    e,
                                    () => {
                                        if (
                                            gridItemsOverFlow
                                        ) {
                                            return null;
                                        } else {
                                            handleCopy();
                                        }
                                    },
                                )
                            }
                        >
                            <AddIcon />
                        </div>
                    </Tooltip>
                    <Tooltip
                        title={`${
                            gridItemsOverFlow
                                ? 'Достигнуто максимальное кол-во сеток'
                                : 'Кол-во созданных сеток'
                        }`}
                    >
                        <div
                            className={cn(
                                s.gridCounter,
                                s.addGridIsDisabled({
                                    isDisabled:
                                        gridItemsOverFlow,
                                }),
                            )}
                        >
                            <Typography size={'14'}>
                                {data.length}/10
                            </Typography>
                        </div>
                    </Tooltip>
                </div>
            </Tooltip>
        </div>
    );
};
