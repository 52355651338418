import { createModel } from '@rematch/core';
import * as CLIENT from '@mega/api';
import {
    MainGrid,
    MainGridCreate,
    MainGridGetItems,
    MainGridQuery,
    MainGridUpdate,
} from '@mega/api';

import type { RootModel } from '../rootModel';
import { DispatchEffectWithDependency } from '../type';

export interface MainGridCreateEffect
    extends DispatchEffectWithDependency<MainGridCreate> {}

export interface MainGridGetItemsEffect
    extends DispatchEffectWithDependency<MainGridGetItems> {}

export interface MainGridUpdateEffect
    extends DispatchEffectWithDependency<MainGridUpdate> {
    query: {
        id: number;
    };
}

export interface MainGridRemoveEffect
    extends Omit<
        DispatchEffectWithDependency<MainGridUpdate>,
        'payload'
    > {
    query: {
        id: number;
    };
}

export interface AutoArticleItem {
    [key: string]: {
        id: number;
        is_active: boolean;
        label: string;
    };
}

export type ActiveItems = {
    CONTENT_ARTICLE: number[];
    CONTENT_AUTO_ARTICLE: AutoArticleItem;
    CONTENT_STORIES: number[];
};

const defaultStore: {
    latest: MainGrid | null;
    active: MainGrid | null;
    list: MainGrid[];
    itemsList: ActiveItems;
    settingsIsOpen: boolean;
    settingsSmartGridIsOff: boolean;
} = {
    latest: null,
    list: [],
    active: null,
    itemsList: {
        CONTENT_ARTICLE: [],
        CONTENT_AUTO_ARTICLE: {},
        CONTENT_STORIES: [],
    },
    settingsIsOpen: false,
    settingsSmartGridIsOff: false,
};

const mainGrid = createModel<RootModel>()({
    name: 'mainGrid',
    state: defaultStore,
    reducers: {
        putLatest: (state, payload: MainGrid) => {
            return {
                ...state,
                latest: payload,
            };
        },
        putList: (state, payload: MainGrid[]) => {
            return {
                ...state,
                list: payload,
            };
        },
        addToList: (state, payload: MainGrid) => {
            return {
                ...state,
                list: [payload, ...state.list],
            };
        },
        putActive: (state, payload: MainGrid) => {
            return {
                ...state,
                active: payload,
            };
        },
        putItemsList: (state, payload: ActiveItems) => {
            return {
                ...state,
                itemsList: payload,
            };
        },
        setSettingsIsOpen: (state, payload: boolean) => {
            return {
                ...state,
                settingsIsOpen: payload,
            };
        },
        setsettingsSmartGridIsOff: (
            state,
            payload: boolean,
        ) => {
            return {
                ...state,
                settingsSmartGridIsOff: payload,
            };
        },
        clear: () => {
            return defaultStore;
        },
    },
    effects: (dispatch) => ({
        get: async (query: MainGridQuery, store) => {
            try {
                const response =
                    await CLIENT.mainGrid.get?.(
                        {
                            ...query,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (response?.ok) {
                    const { data } = response;
                    await dispatch.mainGrid?.putList(
                        data.results,
                    );
                }
            } catch (e) {
                if (
                    CLIENT.mainGrid.get &&
                    e instanceof CLIENT.mainGrid.get.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        getLatest: async (query: MainGridQuery, store) => {
            try {
                const response =
                    await CLIENT.mainGrid.getLatest?.(
                        {
                            ...query,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (response?.ok) {
                    const { data } = response;
                    await dispatch.mainGrid?.putLatest(
                        data,
                    );
                    // if (data.editor_data) {
                    //     dispatch.mainGrid.getItems({
                    //         payload: {
                    //             editor_data:
                    //                 data.editor_data,
                    //         },
                    //     });
                    // }

                    // await dispatch.mainGrid?.putActive(
                    //   data, // TODO доделать
                    // );
                }
            } catch (e) {
                if (
                    CLIENT.mainGrid.getLatest &&
                    e instanceof
                        CLIENT.mainGrid.getLatest.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        goToSite: async (_, store) => {
            const active = store.mainGrid?.active;
            if (!active || !active.url || !active.id)
                return;
            const url = active.is_active
                ? active.url
                : `${active.url}preview/main-grid/${active.id}`;
            window.open(url, '_blank');
        },
        create: async (
            { payload, options }: MainGridCreateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.mainGrid?.create?.(
                        {
                            ...payload,
                            site: store.site.site?.id || '',
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (response?.ok) {
                    dispatch.mainGrid.addToList(
                        response.data,
                    );
                    dispatch.mainGrid.putActive(
                        response.data,
                    );
                    // if (response.data.editor_data) {
                    //     dispatch.mainGrid.getItems({
                    //         payload: {
                    //             editor_data:
                    //                 response.data
                    //                     .editor_data,
                    //         },
                    //     });
                    // }

                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        enqueueSnackbar(
                            'Сетка успешно создана',
                            {
                                variant: 'success',
                            },
                        );
                    }

                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.mainGrid.create &&
                    e instanceof
                        CLIENT.mainGrid.create.Error
                ) {
                    const error = e.getActualType();

                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        if (error.data.title[0]) {
                            enqueueSnackbar(
                                error.data.title[0],
                                {
                                    variant: 'error',
                                },
                            );
                        }
                    }

                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
        update: async (
            {
                query,
                payload,
                options,
            }: MainGridUpdateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.mainGrid?.updatePartialById?.(
                        {
                            ...payload,

                            id: query.id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.mainGrid.get({});
                    dispatch.mainGrid.putActive(
                        response.data,
                    );
                    // if (response.data.editor_data) {
                    //     dispatch.mainGrid.getItems({
                    //         payload: {
                    //             editor_data:
                    //                 response.data
                    //                     .editor_data,
                    //         },
                    //     });
                    // }

                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        enqueueSnackbar(
                            'Сетка успешно обновлена',
                            {
                                variant: 'success',
                            },
                        );
                    }

                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.mainGrid.updatePartialById &&
                    e instanceof
                        CLIENT.mainGrid.updatePartialById
                            .Error
                ) {
                    const error = e.getActualType();

                    if (
                        CLIENT.mainGrid.updatePartialById &&
                        e instanceof
                            CLIENT.mainGrid
                                .updatePartialById.Error
                    ) {
                        const error = e.getActualType();
                        if (error.status === 401) {
                            const { isOk } =
                                await dispatch.auth[401](
                                    error,
                                );
                            if (isOk) {
                                dispatch.mainGrid.update({
                                    query,
                                    payload,
                                });
                            }
                        }
                    }

                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
        remove: async (
            { query, options }: MainGridRemoveEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT?.mainGrid?.deleteById?.(
                        {
                            id: query.id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.mainGrid.get({});

                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        enqueueSnackbar(
                            'Сетка успешно удалена',
                            {
                                variant: 'success',
                            },
                        );
                    }
                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.mainGrid.deleteById &&
                    e instanceof
                        CLIENT.mainGrid.deleteById.Error
                ) {
                    const error = e.getActualType();

                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
        getItems: async (
            { payload, options }: MainGridGetItemsEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.mainGrid?.getItems?.(
                        {
                            ...payload,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.mainGrid.putItemsList(
                        response.data,
                    );

                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;
                    }

                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.mainGrid.getItems &&
                    e instanceof
                        CLIENT.mainGrid.getItems.Error
                ) {
                    const error = e.getActualType();

                    if (
                        CLIENT.mainGrid.getItems &&
                        e instanceof
                            CLIENT.mainGrid.getItems.Error
                    ) {
                        const error = e.getActualType();
                        if (error.status === 401) {
                            const { isOk } =
                                await dispatch.auth[401](
                                    error,
                                );
                            if (isOk) {
                                dispatch.mainGrid.getItems({
                                    payload,
                                });
                            }
                        }
                    }

                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
    }),
});

export { mainGrid };
export default mainGrid;
