import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';
import dayjs from 'dayjs';
import { createPortal } from 'react-dom';
import { useInterval } from 'usehooks-ts';
import { useSnackbar } from 'notistack';
import { Column, CellValue } from 'react-table';
import {
    useArticlesDispatch,
    useArticlesRoom,
    useArticlesRoomDispatch,
    useArticlesStore,
    useSettings,
    useUserInfo,
} from '@mega/store';
import type {
    Articles as ArticlesType,
    UpdateBulk,
} from '@mega/api';

import { listingRecipe, headItem } from '../Listing.css';
import {
    ListingTable,
    Button,
    Stack,
    Checkbox,
    ButtonGroup,
} from '@mega/ui';

import { typographyClasses } from '@mega/styles';

import {
    RssColumn,
    ActionColumn,
    WipColumn,
    AuthorsColumn,
    CoverColumn,
    PublicationColumn,
    TitleColumn,
    CreatedAtColumn,
} from '../Columns';
import { Tag } from '../../Tag';
import {
    EntityAction,
    useCheckPermissions,
} from '@mega/store/lib/src/hooks/User/checkPermissions.hook';
import { Check, Close } from '@mega/icons';
import { listingArticlesClasses } from './ListingArticles.css';

export interface ListingArticlesProps {}

const ListingArticles: FC<ListingArticlesProps> = () => {
    const user = useUserInfo();
    const { enqueueSnackbar } = useSnackbar();
    const { checkPageRoom } = useArticlesRoomDispatch();
    const { clear, updateBulk } = useArticlesDispatch();
    const { config } = useSettings();
    const { pagesWithEditor } = useArticlesRoom();

    const store = useArticlesStore();
    const articlesPerPage = store.query.per_page;
    const { query } = store;
    const queries = useMemo(
        () => Object.entries(query),
        [query],
    );

    const permissionGovContract =
        user.me?.permissions.includes(
            'article.change_is_gov_contract',
        );

    const [selectedRows, setSelectedRows] = useState<
        number[]
    >([]);
    const [selectAll, setSelectAll] = useState(false);

    const isGovContractRequired =
        config.article.change.is_gov_contract &&
        permissionGovContract;

    useEffect(() => {
        setSelectAll(false);
        setSelectedRows([]);
    }, [queries]);

    useEffect(() => {
        checkPageRoom({});
        return () => {
            clear();
        };
    }, [checkPageRoom, clear]);

    useInterval(() => {
        checkPageRoom({});
    }, 5000);

    const hasDeleteArticlePermission = useCheckPermissions({
        entity: 'article',
        action: EntityAction.delete,
    });

    const targetElement = document.getElementById(
        'targetListHeader',
    );

    const hiddenColumns: string[] = [];
    if (!isGovContractRequired) {
        hiddenColumns.push('selectColumn');
    }

    const data = useMemo(() => {
        const values =
            store.pages?.[store.currentPage] || [];
        return Object.values(values).flat();
    }, [store.pages]);

    const handleChangeSelect = (
        e: {
            name: string;
            value: string;
            isChecked: boolean;
        },
        id: number,
    ) => {
        setSelectedRows((prev) => {
            const updatedSelectedRows = e.isChecked
                ? [...prev, id]
                : prev.filter((el) => el !== id);

            setSelectAll(
                updatedSelectedRows.length ===
                    articlesPerPage,
            );
            return updatedSelectedRows;
        });
    };

    const columns: Column<ArticlesType>[] = useMemo(
        () => [
            {
                id: 'selectColumn',
                Header: '',
                //@ts-ignore
                accessor: 'select',
                Cell: ({ row }: CellValue) => (
                    <Checkbox
                        value="1"
                        name="select"
                        label=""
                        checked={selectedRows.some(
                            (el) => el === row.original.id,
                        )}
                        onChange={(e) => {
                            handleChangeSelect(
                                e,
                                row.original.id,
                            );
                        }}
                    />
                ),
                minWidth: 50,
                width: '10%',
            },
            {
                Header: 'Заголовок',
                accessor: (row) => {
                    return {
                        title: row.title,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => {
                    return <TitleColumn {...value} />;
                },
                minWidth: 160,
                width: '100%',
            },
            {
                Header: ' ',
                accessor: (row) => {
                    return {
                        isAutogrid: row.is_autogrid,
                        isInfoUnit: Boolean(row.info_unit),
                        mainPageIndex: row.main_page_index,
                        pubDate: row.pub_date,
                    };
                },
                Cell: ({ value }: CellValue) => {
                    const {
                        isAutogrid,
                        isInfoUnit,
                        mainPageIndex,
                        pubDate,
                    } = value;

                    const isAutogridQueue =
                        isAutogrid &&
                        dayjs(pubDate).diff(dayjs()) > 0;

                    const tagType = isAutogridQueue
                        ? 'queue'
                        : isAutogrid
                        ? 'autogrid'
                        : 'default';

                    const tagText = isAutogrid
                        ? 'A'
                        : mainPageIndex;
                    return (
                        <Stack gap="8">
                            {(mainPageIndex ||
                                isAutogrid) && (
                                <Tag type={tagType}>
                                    {tagText}
                                </Tag>
                            )}
                            {isInfoUnit && (
                                <Tag type="infounit">
                                    ИП
                                </Tag>
                            )}
                        </Stack>
                    );
                },
                maxWidth: 30,
                width: '100%',
            },
            {
                Header: 'Публикация',
                accessor: (row) => {
                    return {
                        data: row.pub_date,
                        pubOn: row.is_active,
                    };
                },

                Cell: ({ value }: CellValue) => (
                    <PublicationColumn {...value} />
                ),
                minWidth: 110,
                maxWidth: 150,
                width: '100%',
            },
            {
                Header: 'Создание',
                accessor: (row) => {
                    return {
                        data: row.created_at,
                    };
                },

                Cell: ({ value }: CellValue) => (
                    <CreatedAtColumn {...value} />
                ),
                minWidth: 110,
                maxWidth: 150,
                width: '100%',
            },
            {
                Header: 'Обложка',
                accessor: (row) => {
                    return {
                        cover: row.cover,
                        video: row.main_video,
                    };
                },
                Cell: ({ value }: CellValue) => {
                    return <CoverColumn {...value} />;
                },
                minWidth: 60,
                maxWidth: 66,
                width: '100%',
            },
            {
                Header: 'RSS',
                accessor: 'rssfeeds',
                Cell: ({ value }) => {
                    return <RssColumn rss={value} />;
                },
                minWidth: 60,
                maxWidth: 66,
                width: '100%',
            },
            {
                Header: 'Действия',
                accessor: (row) => {
                    return {
                        isActive: row.is_active,
                        url: row.url,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => (
                    <ActionColumn
                        {...value}
                        hasDeleteArticlePermission={
                            hasDeleteArticlePermission
                        }
                    />
                ),
                minWidth: 60,
                maxWidth: 66,
                width: '100%',
            },
            {
                Header: 'Авторы',
                accessor: 'authors',
                Cell: ({ value }: CellValue) => {
                    return (
                        <AuthorsColumn authors={value} />
                    );
                },
                minWidth: 60,
                maxWidth: 100,
                width: '100%',
            },
            {
                Header: 'Создатель',
                accessor: 'owner',
                Cell: ({ value }: CellValue) => {
                    return (
                        <AuthorsColumn authors={[value]} />
                    );
                },
                minWidth: 60,
                maxWidth: 100,
                width: '100%',
            },
            {
                Header: 'В работе',
                accessor: 'id',
                Cell: ({ value }: CellValue) => {
                    return <WipColumn id={value} />;
                },
                minWidth: 60,
                maxWidth: 115,
                width: '100%',
            },
            {
                Header: 'Просмотры',
                accessor: 'shows',
                Cell: ({ value }: CellValue) => {
                    //TODO: use classes
                    return (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {value}
                        </div>
                    );
                },
                minWidth: 60,
                maxWidth: 115,
                width: '100%',
            },
        ],
        [selectedRows, selectAll, data],
    );

    return (
        <>
            {isGovContractRequired &&
                targetElement &&
                createPortal(
                    <div
                        className={
                            listingArticlesClasses.header
                        }
                    >
                        <Checkbox
                            className={
                                listingArticlesClasses.checkboxSelectAll
                            }
                            value="selectAll"
                            name="selectAll"
                            label="Добавить все статьи в Госконтракт"
                            checked={selectAll}
                            onChange={(e) => {
                                setSelectAll(e.isChecked);
                                setSelectedRows(() => {
                                    if (!e.isChecked) {
                                        return [];
                                    }
                                    const newSelectedRows: Array<number> =
                                        [];
                                    data.forEach((row) => {
                                        if (row.id) {
                                            newSelectedRows.push(
                                                row.id,
                                            );
                                        }
                                    });
                                    return newSelectedRows;
                                });
                            }}
                        />
                        <ButtonGroup orientation="horizontal">
                            <Button
                                className={
                                    listingArticlesClasses.confirmationButton
                                }
                                label={'Подтвердить'}
                                endIcon={<Check />}
                                disabled={
                                    selectedRows.length < 1
                                }
                                onClick={() => {
                                    //@ts-ignore
                                    const result: UpdateBulk =
                                        selectedRows.map(
                                            (el) => {
                                                return {
                                                    id: el,
                                                    is_gov_contract:
                                                        true,
                                                };
                                            },
                                        );
                                    updateBulk({
                                        payload: result,
                                        options: {
                                            notifier: {
                                                enqueueSnackbar,
                                            },
                                        },
                                    });
                                    setSelectAll(false);
                                    setSelectedRows([]);
                                }}
                            />
                            <Button
                                className={
                                    listingArticlesClasses.resetButton
                                }
                                label={'Сбросить'}
                                endIcon={<Close />}
                                onClick={() => {
                                    setSelectAll(false);
                                    setSelectedRows([]);
                                }}
                            />
                        </ButtonGroup>
                    </div>,
                    targetElement,
                )}

            <ListingTable
                getHeaderGroupProps={(cellInfo) => {
                    return {
                        className: listingRecipe({
                            header: true,
                        }),
                    };
                }}
                getHeaderProps={(cellInfo) => {
                    return {
                        className: headItem({
                            title:
                                cellInfo?.id ===
                                'Заголовок',
                        }),
                    };
                }}
                getRowGroupProps={(cell) => {
                    const { original } = cell || {};
                    const id = original?.id;
                    const editor = pagesWithEditor?.[id];

                    const baseClassName = [
                        typographyClasses.recipe({
                            size: '14',
                        }),
                        listingRecipe({ header: false }),
                    ].join(' ');

                    return {
                        className: original
                            ? baseClassName
                            : [
                                  baseClassName,
                                  cell[0].className,
                              ].join(' '),
                        style: {
                            backgroundColor: editor
                                ? 'var(--bg-editor-secondary, #FFF8FC)'
                                : '',
                            ...(cell[0]?.style || {}),
                        },
                    };
                }}
                columns={columns}
                data={data}
                hiddenColumns={hiddenColumns}
            />
        </>
    );
};

export { ListingArticles };
