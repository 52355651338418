import React, { FC, ReactNode } from 'react';

import { stackClasses, StackClasses } from '@mega/styles';

export interface StackProps {
    children: ReactNode;
}

const Stack: FC<StackProps & StackClasses['recipe']> = ({
    children,
    gap,
}) => (
    <div className={stackClasses.recipe({ gap })}>
        {children}
    </div>
);

export { Stack };
