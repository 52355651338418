import React, { CSSProperties, FC, ReactNode } from 'react';

import {
    typographyRecipe,
    TypographyRecipeVariants,
} from '@mega/styles';

export interface TypographyProps {
    as?: keyof JSX.IntrinsicElements;
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
    title?: string;
}

const Typography: FC<
    TypographyProps & TypographyRecipeVariants
> = ({
    as: Tag = 'p',
    children,
    weight = 'medium',
    size = '32',
    className = '',
    mod = 'none',
    style = {},
    title,
}) => (
    <Tag
        className={[
            className,
            typographyRecipe({
                weight,
                size,
                mod,
            }),
        ].join(' ')}
        style={style}
        title={title}
    >
        {children}
    </Tag>
);

export { Typography };
