import {
    ChangeEvent,
    FC,
    InputHTMLAttributes,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { useField } from 'formik';

import {
    paperClasses,
    typographyClasses,
} from '@mega/styles';
import { Typography } from '@mega/ui';
import {
    inputClasses as classes,
    InputClasses,
} from './Input.css';

export interface InputProps
    extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    isLabelHidden?: boolean;
    showIcon?: (props: { isShow: boolean }) => ReactElement;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    isField?: boolean;
    limit?: number;
    borderRadius?: 'none' | 'small' | 'medium' | 'large';
    placeholderBase?: string | (() => ReactElement);
    isRequired?: boolean;
    min?: number;
    style?: React.CSSProperties & {
        '--input-placeholder-color'?: string;
    };
}

const limitList = {
    title: 120,
    subtitle: 90,
};

const Input: FC<
    InputHTMLAttributes<HTMLInputElement> &
        InputProps &
        InputClasses['recipe']
> = ({
    name,
    type,
    label,
    startIcon,
    endIcon,
    value: externalValue,
    onChange: externalOnChange,
    placeholder = '',
    isError,
    isLabelHidden = false,
    showIcon,
    isField = false,
    dimension = 'large',
    limit,
    borderRadius,
    placeholderBase = undefined,
    isRequired = false,
    disabled = false,
    onBlur,
    onFocus,
    min,
    style,
    ...props
}): JSX.Element => {
    const currentInput = useRef<HTMLInputElement>(null);
    const defaultVirtualType =
        type === 'password' || type === 'text'
            ? type
            : undefined;
    const [virtualType, setVirtualType] = useState(
        defaultVirtualType,
    );

    const [value, setValue] = useState('');
    const interceptValue = externalValue ?? value;

    const [isFocus, setIsFocus] = useState(false);

    const isShrink = label
        ? Boolean(interceptValue) || isFocus
        : true;

    const isPlaceholderUp =
        interceptValue !== '' ? true : isFocus;

    const isVirtualType = type === 'password';
    const isNotVisibility = virtualType === 'password';

    const hasStartIcon = Boolean(startIcon);
    const hasEndIcon =
        Boolean(endIcon) && !(type === 'password');

    const placeholderMod = isShrink ? placeholder : ' ';

    const focusOnInput = () => {
        const element = currentInput.current;
        if (element) {
            if (!isFocus) {
                element?.focus?.();
            }
        }
    };

    const inputShrink =
        placeholder === ' ' || placeholder === ''
            ? false
            : isPlaceholderUp;

    const interceptOnChange = (
        e: ChangeEvent<HTMLInputElement>,
    ) => {
        if (externalValue || externalValue === '') {
            externalOnChange?.(e);
        } else {
            setValue(e.target.value);
        }
    };

    const maxLength = limit && limit;
    return (
        <div
            style={style}
            className={[
                isField ? classes.inputTheme : '',
                paperClasses.recipe({
                    color: 'gray',
                    variant: 'outlineFilled',
                    borderRadius,
                }),
                classes.recipe({
                    isError,
                    dimension,
                }),
            ].join(' ')}
        >
            <div
                className={classes.inputTriggerBG}
                onClick={focusOnInput}
            />
            {hasStartIcon && (
                <div className={classes.inputInjectIcon}>
                    {startIcon instanceof Function
                        ? startIcon()
                        : startIcon}
                </div>
            )}
            <div className={classes.inputLabelBox}>
                {label && (
                    <label
                        htmlFor={name}
                        className={classes.inputLabelRecipe(
                            {
                                isLabelHidden,
                                isFocus: isShrink,
                            },
                        )}
                    >
                        <Typography
                            size="15"
                            weight="medium"
                        >
                            {label}
                        </Typography>
                    </label>
                )}
                {placeholderMod && (
                    <div
                        className={classes.inputPlaceholder(
                            {
                                isFocus: isPlaceholderUp,
                            },
                        )}
                    >
                        {placeholderMod}
                        {isRequired ? (
                            <span
                                className={
                                    classes.inpputIsRequired
                                }
                            >
                                *
                            </span>
                        ) : (
                            ''
                        )}
                        {maxLength && (
                            <p
                                className={
                                    classes.inputPlaceholderCounter
                                }
                            >
                                {
                                    interceptValue.toString()
                                        .length
                                }
                                /{maxLength}
                            </p>
                        )}
                    </div>
                )}
                {placeholderBase && !interceptValue && (
                    <div
                        className={classes.inputPlaceholderBase(
                            {
                                isFocus: isFocus,
                            },
                        )}
                        id={'placeholderBase'}
                    >
                        {typeof placeholderBase ===
                        'function'
                            ? placeholderBase()
                            : placeholderBase}
                    </div>
                )}
                <input
                    disabled={disabled}
                    ref={currentInput}
                    id={name}
                    name={name}
                    type={
                        isVirtualType ? virtualType : type
                    }
                    min={min}
                    className={`${classes.inputInteractiveRecipe(
                        {
                            hasLabel: Boolean(label),
                            isLabelHidden,
                            isFocus: inputShrink,
                            type: type as 'datetime-local',
                        },
                    )} ${typographyClasses.recipe({
                        size: '14',
                        weight: 'regular',
                    })} `}
                    value={interceptValue}
                    onChange={interceptOnChange}
                    onFocus={() => {
                        setIsFocus(true);
                    }}
                    onBlur={() => {
                        setIsFocus(false);
                    }}
                    maxLength={maxLength}
                />
            </div>
            {hasEndIcon && (
                <div className={classes.inputInjectIcon}>
                    {endIcon instanceof Function
                        ? endIcon()
                        : endIcon}
                </div>
            )}
            {isVirtualType && (
                <button
                    type="button"
                    className={classes.inputIconButton}
                    onClick={() =>
                        isNotVisibility
                            ? setVirtualType('text')
                            : setVirtualType('password')
                    }
                >
                    {isNotVisibility
                        ? showIcon?.({ isShow: true })
                        : showIcon?.({ isShow: false })}
                </button>
            )}
        </div>
    );
};

export interface InputFieldProps {
    name: string;
    label?: string;
    type:
        | 'email'
        | 'number'
        | 'text'
        | 'password'
        | 'tel'
        | 'datetime-local';
    placeholder?: string;
    isLabelHidden?: boolean;
    showIcon?: (props: { isShow: boolean }) => ReactElement;
    isRequired?: boolean;
    limit?: number;
    onChange?: (
        event: ChangeEvent<HTMLInputElement>,
    ) => void;
    disabled?: boolean;
    placeholderBase?: (() => ReactElement) | string;
    min?: number;
}

const InputField: FC<
    InputFieldProps & InputClasses['recipe']
> = ({
    name,
    label,
    type,
    placeholder = '',
    isLabelHidden = true,
    showIcon,
    dimension,
    isRequired = false,
    limit,
    onChange,
    disabled = false,
    placeholderBase = undefined,
    min = undefined,
}) => {
    // const { values, setFieldValue } = useFormikContext();
    const [field, meta, setters] = useField<
        string | number
    >({
        name,
        type,
    });
    const isError = Boolean(meta?.error && meta.touched);

    return (
        <div
            className={[
                classes.inputTheme,
                classes.inputField,
                classes.inputIsDisabled({ disabled }),
            ].join(' ')}
        >
            <Input
                placeholderBase={placeholderBase}
                limit={limit}
                dimension={dimension}
                isLabelHidden={isLabelHidden}
                isField={true}
                isError={isError}
                name={name}
                label={label}
                type={type}
                value={field?.value || ''}
                placeholder={`${placeholder}`}
                isRequired={isRequired}
                startIcon={undefined}
                endIcon={undefined}
                showIcon={showIcon}
                onChange={
                    onChange ? onChange : field.onChange
                }
                disabled={disabled}
                min={min}
            />
            {isError ? (
                <Typography
                    className={classes.inputFieldHelperText}
                    size="14"
                >
                    {meta.error}
                </Typography>
            ) : null}
        </div>
    );
};

export { Input, InputField };
