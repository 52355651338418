import React from 'react';
import { DroppableContainer } from '../shared/DroppableContainer';
import { viewStyles as s } from './GridView.css';
import { ElementData } from '../../types/blocks';
import { useDependencyEditorContext } from '../../store/context';
import { useEditor } from '../../store/hooks';
import { renderWrapper } from '@mega/editor/src/feature/CustomSerializer/CustomSerializer.css';
import { SortableContainer } from 'src/pages/smartGrid/components/shared/SortableContainer';
import {
    horizontalListSortingStrategy,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

export interface GridViewProps {}

export type RenderViewProps = {
    data: ElementData;
    currentId: string;
    parentId: string | null;
};
export const RenderView: React.FC<RenderViewProps> = (
    props,
) => {
    const { data, currentId, parentId } = props;
    const { rootElements, pluginElements } =
        useDependencyEditorContext();
    const { getParent } = useEditor();
    const currentElementData = data[currentId];
    const { type, meta } = currentElementData;

    const currentElement =
        type in rootElements
            ? rootElements[type]
            : type in pluginElements
            ? pluginElements[type]
            : null;

    const neighborsList = parentId
        ? data[parentId]?.childrenElement
        : [];

    const neighborsType = getParent(neighborsList[0])?.type;

    if (currentElement?.render) {
        const ComponentRender = currentElement.render;
        return (
            <DroppableContainer
                id={currentId}
                isContainer={currentElementData.isContainer}
                acceptedTypes={
                    currentElementData.acceptedTypes
                }
                neighborsList={neighborsList}
                neighborsType={neighborsType}
                meta={meta}
            >
                <SortableContainer
                    id={currentId}
                    parentId={parentId}
                    isContainer={
                        currentElementData.isContainer
                    }
                >
                    {/*// @ts-ignore*/}
                    <ComponentRender
                        key={currentId}
                        id={currentId}
                        parentId={parentId}
                        type={type}
                        meta={meta}
                    >
                        <SortableContext
                            items={
                                currentElementData?.childrenElement ||
                                []
                            }
                            strategy={
                                currentElementData.type ===
                                'FLOW_STACK'
                                    ? verticalListSortingStrategy
                                    : horizontalListSortingStrategy
                            }
                        >
                            {currentElementData.childrenElement.map(
                                (currentChildrenId) => (
                                    <div
                                        className={
                                            renderWrapper
                                        }
                                        key={
                                            currentChildrenId
                                        }
                                    >
                                        <RenderView
                                            currentId={
                                                currentChildrenId
                                            }
                                            parentId={
                                                currentElementData.id
                                            }
                                            data={data}
                                        />
                                    </div>
                                ),
                            )}
                        </SortableContext>
                    </ComponentRender>
                </SortableContainer>
            </DroppableContainer>
        );
    }

    return null;
};

export const GridView: React.FC<GridViewProps> = (
    props,
) => {
    const {} = props;

    const {
        smartGridEditor: { items, data },
    } = useEditor();

    const itemList = data[items[0]]?.childrenElement || [];
    return (
        <div className={s.wrapper}>
            <SortableContext
                id="root"
                items={itemList}
                strategy={verticalListSortingStrategy}
            >
                {items.map((rootCurrentID) => {
                    return (
                        <RenderView
                            key={rootCurrentID}
                            currentId={rootCurrentID}
                            parentId={null}
                            data={data}
                        />
                    );
                })}
            </SortableContext>
        </div>
    );
};
