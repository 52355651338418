import { FC } from 'react';
import { AutoArticleProps } from './AutoArticle.types';
import { pluginStyles as s } from './AutoArticle.css';
import { DraggableContainer } from 'src/pages/smartGrid/components/shared/DraggableContainer';
import {
    DEFAULT_360_AUTO_ARTICLE,
    DEFAULT_RIAMO_AUTO_ARTICLE,
} from '@pages/smartGrid/components/Plugins';
import { useSiteStore } from '@mega/store';

const AutoArticlePlugin: FC<AutoArticleProps> = ({}) => {
    const currenSite = useSiteStore();
    const is360 = currenSite?.id === '360tv.ru';
    return (
        <DraggableContainer
            meta={
                is360
                    ? DEFAULT_360_AUTO_ARTICLE.meta
                    : DEFAULT_RIAMO_AUTO_ARTICLE.meta
            }
            type={
                is360
                    ? DEFAULT_360_AUTO_ARTICLE.type
                    : DEFAULT_RIAMO_AUTO_ARTICLE.type
            }
        >
            <div className={s.wrapper({})}>Автостатья</div>
        </DraggableContainer>
    );
};

export { AutoArticlePlugin };
